<template>
  <v-form ref="formCfop" lazy-validation>
    <v-container grid-list-md fluid>
      <v-widget title="Detalhe do CFOP">
        <div slot="widget-content">
          <v-row dense>
            <v-col cols="12" sm="2">
              <v-text-field label="CFOP" v-model="item.cfop" readonly></v-text-field>
            </v-col>
            <v-col cols="12" sm="10">
              <v-text-field label="Descrição" v-model="item.descricao" readonly></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-switch label="Utilizado como Itens da NF-e" v-model="item.indNFe" readonly></v-switch>
            </v-col>
            <v-col cols="12" sm="6">
              <v-switch label="Serviço de Comunicação" v-model="item.indComunica" readonly></v-switch>
            </v-col>
            <v-col cols="12" sm="6">
              <v-switch label="Grupo Retenção ICMS Transportes" v-model="item.indTransp" readonly></v-switch>
            </v-col>
            <v-col cols="12" sm="6">
              <v-switch label="Devolução de Mercadorias" v-model="item.indDevol" readonly></v-switch>
            </v-col>
          </v-row>
        </div>
      </v-widget>
    </v-container>
  </v-form>  
</template>


<script>

import {mapState} from "vuex";

export default {

  data: () => ({

    
  }),

  computed: {

    ...mapState('cfop', {
      item: 'itemAtual',
      carregando: 'carregando'
    })
  },

  methods: {
    
    
  }
};
</script>

<style scoped>
.container.grid-list-md .layout .flex{
  padding-top: 0px;
  padding-right: 4px;
  padding-bottom: 0px;
  padding-left: 4px;
}

</style>